import React from "react"
import { Link } from "gatsby"
import { RichText } from "prismic-reactjs"
import Fade from "react-reveal/Fade"
import linkResolver from "src/utils/linkResolver"
import GatsbyLink from "src/utils/GatsbyLink"
import Img from "gatsby-image"

const BlockThreeColumns = props => {
  const bgColour = props.data.primary.background_colour
    .toLowerCase()
    .replace(/ /g, "")
  const textColour = bgColour === "black" ? "text-white" : "text-darkgrey"
  const padding = props.data.primary.remove_bottom_padding ? "pb-0" : ""
  const center = props.data.primary.center_content ? "text-center" : ""
  const titleStyle = props.data.primary.alternative_titles_style
    ? "font-bold text-xs tracking-widest uppercase"
    : "font-display text-gold rfs:text-4xl"
  const separator = props.data.primary.alternative_titles_style ? (
    ""
  ) : (
    <div className="w-10 mt-3 separator"></div>
  )
  const imgMargin = props.data.primary.alternative_titles_style
    ? "mb-4"
    : "mb-10"

  const columns = props.data.items.map((column, index) => {
    if (
      column.column_link.document === null &&
      !column.plain_column_link.text
    ) {
      return (
        <div key={index} className={`group ${column.image_is_icon ? "text-center" : ""}`}>
          {!column.video_link.text && column.image.url && (
            <Img
              fluid={column.image?.fluid}
              alt={column.image?.alt}
              className={`transition duration-500 ease-in-out group-hover:opacity-70 ${imgMargin} ${column.image_is_icon ? "w-[50px] block mx-auto" : ""}`}
            />
          )}
          {column.video_link.text && (
            <video
              className={`transition duration-500 ease-in-out group-hover:opacity-70 inline ${imgMargin}`}
              autoPlay
              loop
              muted
              playsInline
            >
              <source src={column.video_link?.text} type="video/mp4" />
            </video>
          )}
          <h3 className={`${titleStyle}`}>{column.title.text}</h3>
          {separator}
          <div className={`mt-6`}>
            <RichText
              render={column.column_text.richText}
              serializeHyperlink={GatsbyLink}
            />
          </div>
        </div>
      )
    } else {
      return (
        <Link
          to={
            column.plain_column_link?.text
              ? `${column.plain_column_link.text}`
              : linkResolver(column.column_link)
          }
          key={index}
          className="group flex flex-col items-start justify-between h-full"
          title={`${column.title.text}`}
        >
          <div className={`${column.image_is_icon ? "my-0 mx-auto" : ""}`}>
            {column.image.url && (
            <picture>
              <source
                srcSet={`${column.image.url}&w=500&h=500`}
                alt={column.image?.alt}
                width="500"
                height="500"
                media="(max-width: 500px)"
                className={`transition duration-500 ease-in-out group-hover:opacity-70 ${imgMargin}`}
              />
              <source
                srcSet={`${column.image.url}&w=1000&h=1000`}
                alt={column.image?.alt}
                width="1000"
                height="1000"
                media="(max-width: 1023px)"
                className={`transition duration-500 ease-in-out group-hover:opacity-70 ${imgMargin}`}
              />
              <source
                srcSet={`${column.image.url}&w=500&h=500`}
                alt={column.image?.alt}
                width="500"
                height="500"
                media="(max-width: 1920px)"
                className={`transition duration-500 ease-in-out group-hover:opacity-70 ${imgMargin}`}
              />
              <img src={`${column.image.url}&w=500&h=500`} alt={column.image.alt} className={`transition duration-500 ease-in-out group-hover:opacity-70 ${imgMargin} ${column.image_is_icon ? "w-[50px] block mx-auto" : ""}`} width="500" height="500"/>
            </picture>
            )}
            <h3 className={`${titleStyle}`}>{column.title.text}</h3>
            <div className="w-10 mt-3 separator"></div>
            <div className="mt-6 leading-normal">
              <RichText
                render={column.column_text.richText}
                serializeHyperlink={GatsbyLink}
              />
            </div>
          </div>
          {column?.button_text?.text &&
            <div
              className={`${bgColour === 'black' ? 'bg-white text-darkgrey group-hover:bg-gold group-hover:text-white' : 'bg-darkgrey text-white group-hover:bg-gold'} mt-4 text-[0.7em] tracking-[0.15em] font-semibold inline-block rounded-full px-10 py-3 uppercase transition duration-500 ease-in-out focus:outline-none`}
              role="button"
            >
              {`${column?.button_text?.text}`}
            </div>
          }
        </Link>
      )
    }
  })

  return (
    <section className={`block-three-columns bg-${bgColour} ${textColour}`}>
      <div
        className={`container ${padding} ${center} grid grid-cols-1 lg:grid-cols-3 gap-16`}
      >
        <Fade distance="20px" bottom>
          {columns}
        </Fade>
      </div>
    </section>
  )
}

export default BlockThreeColumns
